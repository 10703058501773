:root {
  --greenAlert: linear-gradient(
      0deg,
      rgba(17, 215, 0, 0.2),
      rgba(17, 215, 0, 0.2)
    ),
    #ffffff;
  --redAlert: #f5d9d1;
  --purpleAlert: #d5d6e9;
  --greenBorder: 1px solid #2ba52f;
  --redBorder: 1px solid #cf4317;
  --purpleBorder: 1px solid #2e3192;
  --radius: 4px;
  --text: #373737;
}

.alert {
  position: fixed;
  top: 6.125rem;
  right: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 27rem;
  height: 3.5rem;
  padding: 1rem;
  border-radius: var(--radius);
  z-index: 999;
}

.green {
  background: var(--greenAlert);
  border: var(--greenBorder);
}

.red {
  background: var(--redAlert);
  border: var(--redBorder);
}

.purple {
  background: var(--purpleAlert);
  border: var(--purpleBorder);
}

.alert p {
  color: var(--text);
  font-weight: 500;
}

.alertCross {
  cursor: pointer;
}
