.Login_container{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%
}

.LoginForm_section{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: 60%;
}

.LoginForm_container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.LoginForm_titleBlock{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.LoginForm_title{
    color: #373737;
    line-height: 32px;
}

.LoginForm_Block{
    border-radius: 8px;
    height: 276px;
    width: 380px;
    box-shadow: 0px 6px 15px 0px #89898926;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Login_passwordLabel{
    position: relative;
}

.Login_passwordBtn{
    background: transparent;
    border: none;
    position: absolute;
    top: 10px;
    right: 12px;
    cursor: pointer;
}

.Login_passwordBtn:hover svg path{
    stroke: #131677;
    transition: 0.3s;
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear
{
    display: none;
}

.LoginForm_email,.LoginForm_password{
    width: 300px;
    height: 44px;
    padding-left: 12px;
    border-radius: 4px;
    border: 1px;
    color: #373737;
    border: 1px solid #D9D9D9;
}
.LoginForm_emailError,.LoginForm_passwordError{
    width: 300px;
    height: 44px;
    padding-left: 12px;
    border-radius: 4px;
    border: 1px;
    color: #373737;
    border: 1px solid #CF4317
}

.LoginForm_email{
    margin-bottom: 20px;
}


.LoginForm_emailError{
    margin-bottom: 20px;
}
.LoginForm_email::placeholder{
    opacity: 50%;
}
.LoginForm_emailError::placeholder{
    opacity: 50%;
}

label .LoginForm_password{
    margin-bottom: 44px;
}

.LoginForm_passwordError{
    margin-bottom: 10px;
}
.LoginForm_password::placeholder{
    opacity: 50%;
}
.LoginForm_passwordError::placeholder{
    opacity: 50%;
}

.LoginForm_error{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color:#CF4317;
    font-family:'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    margin-bottom: 10px;
}
.LoginForm_error svg{
    margin-right: 7px;
}
.LoginForm_button{
    width: 300px;
    height: 52px;
    border-radius: 4px;
    border:none;
    gap: 10px;
    background: #2E3192;
    text-align: center;
    color: #FFFFFF;
    font-family: 'Raleway';
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    cursor: pointer;
}

.LoginForm_buttonDisable{
    width: 300px;
    height: 52px;
    border-radius: 4px;
    border:none;
    gap: 10px;
    background: #2E3192;
    text-align: center;
    color: #FFFFFF;
    font-family: 'Raleway';
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    opacity: 50%;
}

.LoginForm_Link{
    font-family: 'Raleway';
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    color: #737373;
    margin-top: 20px;
}


.LoginForm_Link:hover{
    color: #2E3192;
}

.LoginImg_section{
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}

@media screen and (max-width:1400px) {
    .LoginImg_section svg{
        width: 550px;
        height: 650px;
    }
}

.Login_errorTitle{
    margin-bottom: 30px;
    color: #131677;
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: center;
}

.Login_errorText{
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #737373;

}

.Login_errorLink{
    color:#737373;
    cursor: pointer;
    margin:0px 4px 0px 4px;
}

.Login_errorButton{
    width: 300px;
    height: 52px;
    margin-top: 30px;
    border-radius: 4px;
    border:none;
    gap: 10px;
    background: #2E3192;
    text-align: center;
    color: #FFFFFF;
    font-family: 'Raleway';
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    cursor: pointer;
}