.headerButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  object-fit: cover;
  object-position: center;
  border: none;
  outline: none;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  background: transparent;
}
