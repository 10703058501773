.staffMore {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0.75rem 0;
    color: var(--text);
    background: var(--white);
    cursor: pointer;
    user-select: none;
}