:root {
  --text: #373737;
  --textActive: #2e3192;
  --defaultWhite: #ffffff;
  --hoverWhite: #f7f7fb;
  --disabled: #373737;
  --radius: 0.25rem;
  --shadow: drop-shadow(0px 6px 15px rgba(137, 137, 137, 0.15));
  --transition: all 0.3s;
}

.popup {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 2rem;
  padding: 0.1rem;
  cursor: pointer;
}

.popup:hover .popSpread path {
  transition: var(--transition);
  stroke: var(--textActive);
}

.popHighlight path {
  stroke: var(--textActive);
}

.popupEdit {
  position: absolute;
  top: 0;
  left: 100%;
  background: var(--defaultWhite);
  border-radius: var(--radius);
  filter: var(--shadow);
  z-index: 10;
}
