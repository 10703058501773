:root {
  --primary: #2e3192;
  --primaryHover: #f0f0ff;
  --text: #373737;
  --subText: #737373;
  --white: #ffffff;
  --lightGray: #d9d9d9;
  --lightPrimary: #f7f7fb;
  --radius: 0.25rem;
  --transition: all 0.3s;
}

.structureWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.structure {
  width: 100%;
  margin: -3rem 0 0 0;
}

.structureContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 60%;
  padding: 3.75rem 2rem 0 0;
}

/* .structureAddElement {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: var(--white);
  border-radius: var(--radius);
  width: 100%;
  height: 3.87rem;
  margin-bottom: 1.25rem;
  padding: 0 1.6rem;
} */

.structureAddElemeText {
  margin-left: 2rem;
  padding: 0 0 0.25rem 0;
}
