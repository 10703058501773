:root {
  --primary: #2e3192;
  --primaryActive: #131677;
  --text: #373737;
  --white: #ffffff;
  --lightGray: #d9d9d9;
  --radius: 0.25rem;
  --transition: all 0.3s;
  --darkBg: rgba(0, 0, 0, 0.5);
  --hoverShadow: 0px 0px 15px rgba(140, 140, 140, 0.25);
}

.popupEditManage,
.disabled {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem;
  transition: var(--transition);
  background: var(--defaultWhite);
  cursor: pointer;
}

.popupEditManage:hover {
  background: var(--hoverWhite);
}

.popupEditManage:hover .popupEditName {
  color: var(--textActive);
}

.popupEditManage:active {
  background: var(--defaultWhite);
}

.disabled {
  opacity: 0.5;
  user-select: none;
}

.disabled:hover {
  background: var(--defaultWhite);
  cursor: default;
}

.popupEditIcon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
}

.popEdit path {
  transition: var(--transition);
}

.popupEditName {
  font-weight: 400;
  font-size: 0.87rem;
  line-height: 1.25rem;
  color: var(--text);
}

.popupEditManage:hover .popEdit path {
  stroke: var(--textActive);
}

.popupEditName:active {
  color: var(--textActive);
}

/* MODAL STYLES */
.modalForm {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.modalBtn {
  padding: 1rem 2rem;
  margin-top: 5rem;
  background: var(--primary);
  color: var(--white);
  border: none;
  border-radius: var(--radius);
  outline: none;
  cursor: pointer;
  transition: var(--transition);
}

.modalBtn:hover {
  box-shadow: var(--hoverShadow);
}

.modalBtn:active {
  background: var(--primaryActive);
}

.disable {
  opacity: 0.5;
  cursor: initial;
}

.disable:hover {
  box-shadow: none;
}

.disable:active {
  background: var(--primary);
}
