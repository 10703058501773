:root {
  --primary: #2e3192;
  --primaryActive: #131677;
  --text: #373737;
  --borderBtn: 1px solid #737373;
  --white: #ffffff;
  --lightGray: #d9d9d9;
  --darkBg: rgba(0, 0, 0, 0.7);
  --radius: 0.25rem;
  --transition: all 0.3s;
  --hoverShadow: 0px 0px 15px rgba(140, 140, 140, 0.25);
}

.popupEditManage,
.disabled {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem;
  transition: var(--transition);
  background: var(--defaultWhite);
  cursor: pointer;
}

.popupEditManage:hover {
  background: var(--hoverWhite);
}

.popupEditManage:hover .popupEditName {
  color: var(--textActive);
}

.popupEditManage:active {
  background: var(--defaultWhite);
}

.disabled {
  opacity: 0.5;
  user-select: none;
}

.disabled:hover {
  background: var(--defaultWhite);
  cursor: default;
}

.popupEditIcon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
}

.popEdit path {
  transition: var(--transition);
}

.popupEditName {
  font-weight: 400;
  font-size: 0.87rem;
  line-height: 1.25rem;
  color: var(--text);
}

.popupEditManage:hover .popEdit path {
  stroke: var(--textActive);
}

.popupEditName:active {
  color: var(--textActive);
}

/* DELETE FORM */
.delete {
  margin-top: -2rem;
}

.deleteBtns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 2.5rem 0 0;
}

.deleteAbord,
.deleteRemove {
    padding: 1rem 2rem;
    border-radius: var(--radius);
    outline: none;
    cursor: pointer;
    transition: var(--transition);
}

/* ABORD BTN */
.deleteAbord {
    margin-right: 2rem;
    background: transparent;
    border: var(--borderBtn);
    color: var(--text);
}

.deleteAbord:hover {
    box-shadow: 0px 0px 15px rgba(140, 140, 140, 0.25);
}

.deleteAbord:active {
    border: 1px solid var(--primary);
    color: var(--primary);
}

.deleteAbord:disabled {
    opacity: 0.5;
    background: transparent;
    box-shadow: none;
    cursor: auto;
}

/* DELETE BTN */
.deleteRemove {
    background: var(--primary);
    border: none;
    color: var(--white);
}

.deleteRemove:hover {
    box-shadow: 0px 0px 15px rgba(140, 140, 140, 0.25);
}

.deleteRemove:active {
    background: var(--primaryActive);
}

.deleteRemove:disabled {
    opacity: 0.5;
    background: var(--primary);
    box-shadow: none;
    cursor: auto;
}
