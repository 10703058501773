:root {
  --primary: #2e3192;
  --primaryActive: #131677;
  --text: #373737;
  --white: #ffffff;
  --lightGray: #d9d9d9;
  --radius: 0.25rem;
  --transition: all 0.3s;
  --darkBg: rgba(0, 0, 0, 0.7);
  --hoverShadow: 0px 0px 15px rgba(140, 140, 140, 0.25);
}

.structureAddElement {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: var(--white);
  border-radius: var(--radius);
  width: 100%;
  height: 3.87rem;
  margin-bottom: 1.25rem;
  padding: 0 1.6rem;
  cursor: pointer;
}

.structureAddElement:hover .plusIcon path {
  transition: var(--transition);
  stroke: var(--primary);
}

.structureAddElement:hover .structureAddElemeText {
  color: var(--primary);
}

.modalPlus {
  margin-right: 1.75rem;
}

.modalOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: var(--darkBg);
  z-index: 1000;
}

.modalContent {
  position: relative;
  width: 36.6rem;
  padding: 2.5rem;
  background: var(--white);
  border-radius: var(--radius);
}

.modalContent h2 {
  margin: 0 0 3.75rem 0;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: var(--primary);
}

.modalCross {
  position: absolute;
  top: -2rem;
  right: -2rem;
  cursor: pointer;
}

.modalForm {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.modalBtn {
  padding: 1rem 2rem;
  margin-top: 5rem;
  background: var(--primary);
  color: var(--white);
  border: none;
  border-radius: var(--radius);
  outline: none;
  cursor: pointer;
  transition: var(--transition);
}

.modalBtn:hover {
  box-shadow: var(--hoverShadow);
}

.modalBtn:active {
  background: var(--primaryActive);
}

.disable {
  opacity: 0.5;
  cursor: initial;
}

.disable:hover {
  box-shadow: none;
}

.disable:active {
  background: var(--primary);
}
