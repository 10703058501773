:root {
  --primary: #2e3192;
  --text: #373737;
  --white: #ffffff;
  --lightGray: #d9d9d9;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Raleway';
  color: var(--text);
}

html {
  height: 100%;
}

body {
  height: 100%;
}

h1 {
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 3rem;
}

.activeModal {
  overflow: hidden;
}

.container {
  display: flex;
  flex-direction: row;
  position: relative;
  background: #fcfcfc;
  min-height: 100vh;
}

.Main {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-left: 10rem;
  padding: 0 2rem;
}

.Header {
  display: flex;
  justify-content: flex-end;
  padding: 1.6rem 0 0 0;
}

.container_main {
  display: flex;
  justify-content: space-between;
}

.alertContainer {
  opacity: 0;
  transition: all 0.3s;
}
