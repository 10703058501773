:root {
  --primary: #2e3192;
  --text: #373737;
  --white: #ffffff;
  --lightGray: #d9d9d9;
  --lightHover: #f7f7fb;
  --radius: 0.25rem;
  --transition: all 0.3s;
}

.branch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--white);
  border-radius: var(--radius);
  width: 100%;
  height: 3.87rem;
  margin-bottom: 1.25rem;
  padding: 0 1.6rem;
}

@media screen and (min-width: 768px) {
  .branch {
    padding: 0 0.6rem;
  }
}

.branchArrow {
  /* Временно скрыто */
  opacity: 0;
  flex: 0 0 1%;
  display: flex;
  justify-content: flex-start;
  transition: var(--transition);
  margin-right: 0.5rem;
  /* cursor: pointer; */
}

.branchName {
  flex: 0 1 70%;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: grab;
}

.branchName p {
  width: 10rem;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (min-width: 768px) {
  .branchName p {
    width: 5vw;
  }
}

@media screen and (min-width: 1024px) {
  .branchName p {
    width: 7rem;
  }
}

@media screen and (min-width: 1440px) {
  .branchName p {
    width: 18rem;
  }
}

.treeMenu {
  flex: 0 0 25%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.branchStaff {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
}

.branchStaff svg path {
  stroke: var(--lightGray);
}
.branchStaff:hover svg path {
  stroke: var(--primary);
  transition: var(--transition);
}

.branchStaffChosen {
  background: var(--lightHover);
  border-radius: var(--radius);
  transition: var(--transition);
}

.branchStaffChosen svg path {
  stroke: var(--primary);
  transition: var(--transition);
}

.branchStaffCount {
  margin-left: 0.5rem;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: var(--text);
}

.branchStaffChosen span {
  color: var(--primary);
}

.branchEditMenu {
  position: relative;
  flex: 0 0 auto;
  margin-right: 0.5rem;
}

.branchPlus {
  flex: 0 0 auto;
}

.active {
  transform: rotate(90deg);
}

.active svg path {
  stroke: var(--primary);
}

.branchLvl2 {
  width: 96%;
  height: 54px;
}

.branchLvl3 {
  width: 92%;
  height: 46px;
}

.branchLvl4 {
  height: 38px;
  width: 88%;
}
.branchLvl4 .treeMenu .branchEditMenu {
  margin-right: 82px;
}