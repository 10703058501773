:root {
  --red: #CF4317;
  --orange: #FF8E25;
  --yellow: #F0D031;
  --lightGreen: #9EDC50;
  --green: #2BA52F;
}

.staff {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 40%;
  height: 76vh;
  margin: 0 0 -3rem 0;
  padding-top: 65px;
}

.staffMenu {
  position: relative;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.75rem;
  height: 50px;
}

.infoOn {
  justify-content: space-between;
}

.staffSearch {
  position: absolute;
  right: 5px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;  
}

.searchInput{
  width: 280px;
  height: 44px;
  padding-left: 20px;
  border-radius: 4px;
  font-family: 'Raleway';
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  border: none;
  outline: none;
}
.searchInput::placeholder{
  color:#D9D9D9;

}

.staffInfoIcon,
.search,
.dots {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.75rem;
  margin-right: 0.75rem;
  background: var(--white);
  border-radius: var(--radius);
  transition: var(--transition);
  cursor: pointer;
}

.staffElementDescription{
  overflow: hidden;
  transition: var(--transition);
  box-shadow: 0px 6px 15px 0px #8989894D;
  position: absolute;
  top: 50px;
  right: 1px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 20px 0px 20px;
  width: 620px;
  height: 116px;
  background: #fff;
}

.staffElementDescriptionTitle{
  font-family: 'Raleway';
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.staffElementDescriptionTeams{
  font-family: 'Raleway';
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 16px;
}

.staffInfoIcon:hover,
.search:hover,
.dots:hover {
  background: var(--primaryHover);
}

.staffInfoIcon:hover svg path,
.search:hover svg path,
.dots:hover svg path {
  stroke: var(--primary);
}

.dots {
  margin-right: 0;
}

/* STAFF HEADER */
.staffHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1.75rem 2.5rem;
  background: var(--lightPrimary);
  border-radius: var(--radius);
}

.staffSurnameWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 77%;
}
.staffSurname{
  margin-left: 65px;
  color: var(--subText);
}

.staffProffesion {
  color: var(--subText);
}

/* .staffProffesion {
    margin-right: 14%;
} */

.staffContent {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  background: var(--white);
  overflow-y: scroll;
}

.staffContent::-webkit-scrollbar{
  width: 5px;
} 
.staffContent::-webkit-scrollbar-track{
  background-color: white;
}

.staffContent::-webkit-scrollbar-thumb{
  background-color: #D9D9D9; 
  border-radius: 40px;
  height: 48px;
}

.staffWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.staffWrapper p {
  text-align: center;
}

.staffWrapper span {
  margin: 0 1.5rem;
  box-shadow: 0px 6px 15px #89898926;
}

.empty {
  width: 70%;
  margin: 0 auto;
  transform: translate(0%, 25vh);
}

/* FILL STAFF */
.staffItem {
  display: flex;
  width: 100%;
  padding: 1.75rem 2.5rem;
}

.staffInfoWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  /* margin-left: 5rem; */
}

.staffInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 50%;
}

.staffInfoPersonIcon {
  margin-right: 1.75rem;
}

.staffInfoPersonIcon img {
  border: 3px solid var(--white);
  border-radius: 50%;
}

.intern svg {
  border: 3px solid var(--red);
  border-radius: 50%;
}

.jun svg {
  border: 3px solid var(--orange);
  border-radius: 50%;
}

.mid svg {
  border: 3px solid var(--yellow);
  border-radius: 50%;
}
.mid svg circle {
  fill: var(--yellow);
  stroke:  var(--yellow);
}

.senior svg {
  border: 3px solid var(--lightGreen);
  border-radius: 50%;
}
.senior svg circle {
  fill:  var(--lightGreen);
  stroke:  var(--lightGreen);
}

.lid svg {
  border: 3px solid var(--green);
  border-radius: 50%;
}
.lid svg circle {
  fill: var(--green);
  stroke: var(--green);
}

