:root {
    --primary: #2e3192;
    --primaryActive: #131677;
    --text: #373737;
    --borderBtn: 1px solid #737373;
    --white: #ffffff;
    --lightGray: #d9d9d9;
    --darkBg: rgba(0, 0, 0, 0.7);
    --radius: 0.25rem;
    --transition: all 0.3s;
    --hoverShadow: 0px 0px 15px rgba(140, 140, 140, 0.25);
  }
  
  .modal{
    display: none;
  }

  .modalActive {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: var(--darkBg);
    z-index: 1000;
  }

  .modalContent {
    position: relative;
    width: 36.6rem;
    padding: 2.5rem;
    background: var(--white);
    border-radius: var(--radius);
  }
  
  .closeModal {
    margin-top: -2rem;
  }
  
  .closeBtns {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 2.5rem 0 0;
  }
  
  .closeBntModal,
  .saveCloseBtnModal {
      padding: 1rem 2rem;
      border-radius: var(--radius);
      outline: none;
      cursor: pointer;
      transition: var(--transition);
  }
  
  .closeBntModal {
      margin-right: 2rem;
      background: transparent;
      border: var(--borderBtn);
      color: var(--text);
  }
  
  .closeBntModal:hover {
      box-shadow: 0px 0px 15px rgba(140, 140, 140, 0.25);
  }
  
  .closeBntModal:active {
      border: 1px solid var(--primary);
      color: var(--primary);
  }
  
  .closeBntModal:disabled {
      opacity: 0.5;
      background: transparent;
      box-shadow: none;
      cursor: auto;
  }
  
  .saveCloseBtnModal {
      background: var(--primary);
      border: none;
      color: var(--white);
  }
  
  .saveCloseBtnModal:hover {
      box-shadow: 0px 0px 15px rgba(140, 140, 140, 0.25);
  }
  
  .saveCloseBtnModal:active {
      background: var(--primaryActive);
  }
  
  .saveCloseBtnModal:disabled {
      opacity: 0.5;
      background: var(--primary);
      box-shadow: none;
      cursor: auto;
  }

  .modalCross {
    position: absolute;
    top: -2rem;
    right: -2rem;
    cursor: pointer;
  }
  
  .modalContent h2 {
    margin: 0 0 3.75rem 0;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: var(--primary);
  }