:root {
  --primary: #2e3192;
  --primaryActive: #131677;
  --text: #373737;
  --white: #ffffff;
  --lightGray: #d9d9d9;
  --radius: 0.25rem;
  --transition: all 0.3s;
  --darkBg: rgba(0, 0, 0, 0.7);
  --hoverShadow: 0px 0px 15px rgba(140, 140, 140, 0.25);
}

.modalOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: var(--darkBg);
  z-index: 1000;
}

.modalContent {
  position: relative;
  width: 36.6rem;
  padding: 2.5rem;
  background: var(--white);
  border-radius: var(--radius);
}

.modalContent h2 {
  margin: 0 0 3.75rem 0;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: var(--primary);
}

.modalCross {
  position: absolute;
  top: -2rem;
  right: -2rem;
  cursor: pointer;
}
