.TeamSelect_div{
    display: flex;
    flex-direction: column;
}

.TeamSelect_divInput{
    position: relative;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 505px;
    border: solid 1px #D9D9D9;
    border-radius: 4px;
}

.TeamSelect_divInput:hover{
    border: solid 1px #2E3192;
}

.TeamSelect_divInput svg path{
    transition: all 0.3s;
}

.TeamSelect_divInput label{
    position: absolute;
    right: 2px;
}

.TeamSelect_input{
    width: 200px;
    height: 48px;
    outline:none;
    border: none;
    padding-left: 20px;
}

.TeamSelect_list{
    width: 505px;
    max-height: 135px;
    background-color: white;
    margin-top: 8px;
    border-radius: 4px;
    overflow: auto;
}

.TeamSelect_list::-webkit-scrollbar{
    width: 5px;
} 
.TeamSelect_list::-webkit-scrollbar-track{
    background-color: white;
}

.TeamSelect_list::-webkit-scrollbar-thumb{
    background-color: #D9D9D9; 
    border-radius: 40px;
    height: 48px;
  }

.TeamSelect_list li{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 490px;
    height: 44px;
    font-family: 'Raleway';    
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
}

.TeamSelect_button{
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    font-family: 'Raleway';    
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.TeamSelect_button svg{
    margin-right: 16px;
}

.chosenTeams_div{
    display: flex;
    flex-direction: row;
}

.chosenTeam{
    margin-right: 5px; 
    font-family: 'Raleway';    
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
}

.checkedTeamSvg{
    display: none;
}
.checkedTeamSvgActive{
    display: block;
}

.inputArrowButton{
    
    border: none;
    background: white;
    cursor: pointer;
}

.TeamSelect_button-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 30%;
}

.TeamSelect_list li:hover{
    background-color: #F7F7FB;
}