:root {
  --primary: #2e3192;
  --text: #373737;
  --lightHover: #f7f7fb;
  --disable: #f1f1f1;
  --radius: 8px;
  --transition: all 0.3s;
}

.navigation {
  position: absolute;
  top: 0;
  left: 0;
  width: 7rem;
  height: 100%;
  z-index: 6;
}

.navigationWrapper {
  width: 7rem;
  height: 100%;
  box-shadow: 0px 6px 15px rgba(137, 137, 137, 0.15);
  transition: var(--transition);
}

.navigationUl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  margin: 0;
  padding-left: 0;
  background: #fff;
  border-radius: var(--radius);
  list-style: none;
}

.navigation li {
  width: 100%;
}

.navigation li:first-child {
  margin-bottom: 5rem;
}

.navigation:hover .navigationWrapper {
  width: 18rem;
}

.navigation:hover .navigationTitle {
  transform: translate(0, 0);
}

.navigation:hover .navigationTitle,
.navigation:hover .navigationLogoTitle {
  opacity: 1;
  transition-delay: 0.3s;
}

.navigationLink {
  text-decoration: none;
}

.navigationElem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* min-width: 13vw; */
  padding: 1.62rem 1.75rem;
  border-radius: var(--radius);
  cursor: pointer;
  transition: var(--transition);
}

.navigationElem:hover {
  background: var(--lightHover);
}

.navigationLogoIcon {
  width: 2.5rem;
}

.navigationElem:hover svg path {
  stroke: var(--primary);
}

.navigationElem:hover .navigationLogoIcon svg path {
  stroke: inherit;
}

.navigationElem:hover .navigationTitle {
  color: var(--primary);
}

.navigationLogoTitle {
  margin-left: 1.18rem;
  font-family: 'Raleway';
  font-style: normal;
  font-size: 1.75rem;
  font-weight: 500;
  color: var(--text);
  opacity: 0;
  transition: var(--transition);
}

.navigationIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.75rem;
  height: 3.75rem;
  padding: 0 1rem;
  background: #ffffff;
  border-radius: var(--radius);
  transition: var(--transition);
  z-index: 5;
}

.navigationElem:hover .navigationIcon {
  background: var(--lightHover);
}

.navigationTitle {
  padding-left: 1rem;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--text);
  opacity: 0;
  transition: var(--transition);
  white-space: nowrap;
  transform: translate(-5rem, 0);
}

.active svg path {
  stroke: var(--primary);
}

.active .navigationIcon {
  background: var(--lightHover);
}

.active .navigationTitle {
  color: var(--primary);
  /* display: block; */
}
