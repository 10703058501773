:root {
    --primary: #2E3192;
    --white: #ffffff;
    --grey: #D9D9D9;
    --transition: all 0.3s;
}

.checkbox {
    position: relative;
}

.checkboxFancy {
    position: absolute;
    width: 1.25rem;
    height: 1.25rem;
    background: var(--white);
    border: 1px solid var(--grey);
    border-radius: 2px;
    transition: var(--transition);
}

.checkboxFancy::before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--primary);
    background-image: url(../../../../assets/icons/check.svg);
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 1px;
    opacity: 0;
    transition: var(--transition);
    z-index: 3;
}

.checkbox input {
    position: absolute;
    appearance: none;
}

.checkbox input:checked + .checkboxFancy::before {
    opacity: 1;
}

.checkbox input:focus + .checkboxFancy {
    border: 1px solid var(--primary);
}