:root {
  --size1: 1rem;
  --sizeO75: 0.75rem;
  --width31: 31rem;
  --text: #373737;
  --lightGrey: #d9d9d9;
  --diasbleGrey: #d9d9d9;
  --error: #cf4317;
  --border: 1px solid #d9d9d9;
  --borderFocus: 0.5px solid #2e3192;
  --borderTexting: 1px solid #2e3192;
  --borderError: 1px solid #cf4317;
  --bg: #ffffff;
  --transition: all 0.3s;
}

.lable {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--text);
  font-size: var(--size1);
}

.errorBorder {
  border: var(--borderError);
}

.lableInput {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 0.5rem;
  padding: 0.8rem 0.9rem;
  font-family: sans-serif;
  font-size: var(--size1);
  color: var(--text);
  border: var(--border);
  border-radius: 0.25rem;
  resize: none;
}

.lableInput:focus {
  outline: none;
  border: var(--borderFocus);
}

.lableInput::placeholder {
  color: var(--lightGrey);
  font-size: var(--size1);
  font-weight: 500;
}

.disabled {
  color: var(--lightGrey);
}

.disabled .lableInput {
  border: none;
}

.disabled .lableInput::placeholder {
  opacity: 0;
}

.nonError {
  margin-top: 0.1rem;
  opacity: 0;
}

.lableError {
  margin-top: 0.1rem;
  padding: 0.18rem;
  font-size: var(--sizeO75);
  color: var(--error);
  opacity: 1;
  transition: var(--transition);
}
