.pagination {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 1.25rem 2.5rem;
  background: var(--white);
  border-radius: 0 0 var(--radius) var(--radius);
}

.paginationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationArrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
  transition: var(--transition);
}

.paginationArrow:hover {
  background: #f7f7fb;
}

.paginationArrow:active {
  background: #f7f7fb;
}

.paginationArrow:active path {
  stroke: var(--primary);
  transition: var(--transition);
}

.paginationPageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem;
  user-select: none;
}

.paginationPageWrapper:last-child,
.paginationPage:last-child {
  margin-right: 0;
}

.paginationPage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 0.5rem;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #d9d9d9;
  border-radius: var(--radius);
  cursor: pointer;
  transition: var(--transition);
}

.paginationPage:hover {
  background: #f7f7fb;
  color: #d9d9d9;
}

.active {
  background: #f7f7fb;
  color: var(--primary);
}

.active:hover {
  color: var(--primary);
}

.disable {
  opacity: 0.2;
}

.paginationDots {
  margin-right: 0.5rem;
  color: var(--text);
  transition: var(--transition);
  cursor: pointer;
}
